import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import {ErrorInfo, InputField, UserDetail} from '../types/';
import * as API from '../utils/api'
import {getFieldByPropName} from "../utils/utils";
import Box from "@mui/material/Box";
import {InputCard} from "../molecules/InputCard";
import {ErrorFeedBack} from "../molecules/ErrorFeedback";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PasswordResetCard from "./PasswordResetCard";

interface UserDetailCardProps {
}

type UserName = {
    lastname: string
    firstname: string
}

type Invitation = {
    email: string
    lastname: string
    firstname: string
}
const UserDetailCard: React.FC<UserDetailCardProps> = () => {

    const [userDetail, setUserDetail] = useState<UserDetail>()
    const [error, setError] = useState<ErrorInfo>()
    const [openPasswordChange, setOpenPasswordChange] = React.useState(false);


    const getInputField = (userDetail?: UserDetail): InputField<string>[] => ([
        {type: 'text', propName: 'lastname', label: '姓', value: userDetail?.user.lastname || '', required: true},
        {type: 'text', propName: 'firstname', label: '名', value: userDetail?.user.firstname || '', required: true},
    ])

    const setInputField = (username: UserName, fields: InputField<string>[]) => {
        username.lastname = getFieldByPropName<string>(fields, 'lastname')?.value || '';
        username.firstname = getFieldByPropName<string>(fields, 'firstname')?.value || '';
    }

    const getInvitationInputField = (): InputField<string>[] => ([
        {type: 'text', propName: 'email', label: 'メールアドレス', value: '', required: true},
        {type: 'text', propName: 'lastname', label: '姓', value: '', required: true},
        {type: 'text', propName: 'firstname', label: '名', value: '', required: true},
    ])

    const setInvitationInputField = (invitation: Invitation, fields: InputField<string>[]) => {
        invitation.email = getFieldByPropName<string>(fields, 'email')?.value || '';
        invitation.lastname = getFieldByPropName<string>(fields, 'lastname')?.value || '';
        invitation.firstname = getFieldByPropName<string>(fields, 'firstname')?.value || '';
    }

    useEffect(() => {
        API.post('/user/detail').then((data) => {
            const userDetail = data.detail as UserDetail
            setUserDetail(userDetail)
        }).catch((e) => {
            console.error(e)
        })
    }, [setUserDetail])

    const onUpdateUserName = useCallback(async (fields: InputField<string>[]) => {

        const username: UserName = {
            lastname: '',
            firstname: ''
        }
        setInputField(username, fields);
        await API.post('/user/update-name', username)
    }, [setError])

    const onInviteUser = useCallback(async (fields: InputField<string>[]) => {

        const invitation: Invitation = {
            email: '',
            lastname: '',
            firstname: ''
        }
        setInvitationInputField(invitation, fields);
        await API.post('/user/invite', invitation)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const userDetailRes = await API.post('/user/detail')
        setUserDetail(userDetailRes.detail as UserDetail)
    }, [setError, setUserDetail])


    const handleLogout = useCallback(async () => {
        console.log('logout')
        await API.get('/logout').then(() => {
            window.location.href = '/'
        })
    }, [])


    const handleChangePassword = useCallback(() => {
        setOpenPasswordChange(true)
    }, [setOpenPasswordChange])

    const handleCloseChangePassword = useCallback(() => {
        setOpenPasswordChange(false)
    }, [setOpenPasswordChange])

    return (
        <Box sx={{
            backgroundColor: "#ffffff",
            width: '400px',
            right: 0,
            padding: '16px',
            margin: '16px',
            ' li': {
                'a.active': {
                    fontWeight: 'bold'
                },
                cursor: 'pointer',
                ':hover': {
                    backgroundColor: '#dddddd'
                }
            }
        }}><>
            {userDetail && (<InputCard
                id={'owner-detail'}
                title={'ユーザー情報'}
                fields={getInputField(userDetail)}
                onAction={onUpdateUserName}
                actionLabel={'更新'}
                heightFixed={false}
                design={'Box'}
            />)}
            {userDetail?.invitations !== undefined && userDetail?.invitations?.length > 0 && (
                <Box>
                    <>
                        <Box>招待した人</Box>
                        {userDetail?.invitations?.map(invitation => (<Box>{invitation}</Box>))}
                    </>
                </Box>)}
            {((userDetail?.invitationStock || 0) - (userDetail?.invitations?.length || 0) > 1) && (
                <Box>
                    <>
                        <Box>招待枠が残っています</Box>
                        <InputCard
                            id={'owner-detail'}
                            title={'招待する方の情報'}
                            fields={getInvitationInputField()}
                            onAction={onInviteUser}
                            actionLabel={'更新'}
                            heightFixed={false}
                            design={'Box'}
                        /></>
                </Box>)}
            <Box><Button onClick={handleChangePassword}>パスワード変更</Button></Box>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Box><Button onClick={handleLogout}>ログアウト</Button></Box>
            <ErrorFeedBack feedbackText={error?.message || ''} savedTimestamp={error?.timestamp || 0}/>
            <Dialog open={openPasswordChange}
                    onClose={handleCloseChangePassword}>
                <PasswordResetCard onComplete={handleCloseChangePassword}/>
            </Dialog>

        </>
        </Box>
    );
}

export default UserDetailCard;