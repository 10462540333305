import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../theme';
import createEmotionCache from '../createEmotionCache';
import {MainTemplate} from "../templates/MainTemplate";
import {useCallback, useState} from "react";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache

}

export default function MyApp(props: MyAppProps) {
    const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;
    const NewrelicBrowserAgent = dynamic(() => import('../atoms/NewRelicBrowserAgentComponent').then((mod) => mod.NewRelicBrowserAgentComponent), {ssr:false})
    const [browserAgent, setBrowserAgent] = useState<any>(undefined)
    const setBrowserAgentHandler = useCallback((agent: any)=> {
        setBrowserAgent(agent)
    }, [setBrowserAgent])
    const getBrowserAgentHandler = useCallback(()=> {
        return browserAgent
    }, [browserAgent])
    return (
        <CacheProvider value={emotionCache}>
            <NewrelicBrowserAgent setBrowserAgentHandler={(agent)=>setBrowserAgentHandler(agent)} />
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width"/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap" />
                <title>New Relic Dojo web</title>
            </Head>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                {props.router.pathname.startsWith('/admin') || props.router.pathname.startsWith('/play') || props.router.pathname.startsWith('/score')
                    ? (<Component {...pageProps} getBrowserAgentHandler={getBrowserAgentHandler} />)
                    : (<MainTemplate>
                        <Component {...pageProps} getBrowserAgentHandler={getBrowserAgentHandler} />
                    </MainTemplate>)
                }
            </ThemeProvider>
        </CacheProvider>
    );
}