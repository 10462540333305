import axios from "axios";
import * as newrelic from "./newrelic-browser"
import logger from "../logs/logger";

export const get = async (path: string, options?: any): Promise<any> => {
    // @ts-ignore
    const url = (process.env.NEXT_PUBLIC_BK_DIRECT === 'true' ? process.env.NEXT_PUBLIC_API_URL : '') + process.env.NEXT_PUBLIC_API_PREFIX + path
    try {
        const response = await axios.get(url, {...options, withCredentials: true})
        if (response.status === 401) {
            console.error(response)
        }
        return response.data
    } catch (error: any) {
        const err = new Error(`GET ${url} is faled ${JSON.stringify(error.response)}`)
        throw err
    }
}

export const post = async (path: string, object?: any, options?: any, needRedirect: boolean = true): Promise<any> => {
    // @ts-ignore
    const url = process.env.NEXT_PUBLIC_API_PREFIX + path
    const _object = { ...object }
    if (!_object.username) {
        //_object.token = localStorage.getItem('token')
    }
    try {
        const response = await axios.post(url, _object, {...options, withCredentials: true})
        if (response.status === 401) {
            console.error(response)
        }
        if (newrelic !== undefined && response.data?.userId !== undefined) {
            newrelic.setCustomAttribute('enduser.id', response.data.userId)
        }
        if (newrelic !== undefined && response.data?.profile?.user?.id !== undefined) {
            newrelic.setCustomAttribute('enduser.id', response.data?.profile?.user?.id)
        }
        return response.data
    } catch (e: any) {
        console.error(e)
        if (e?.response?.status) {
            needRedirect && (window.location.href = '/')
        } else {
            console.error(e)
        }
        return undefined;
    }
}