import * as React from 'react';
import {useCallback, useState} from "react";
import {InputField, ErrorInfo} from '../types/';
import * as API from '../utils/api'
import {InputCard} from "../molecules/InputCard";
import {debounce} from "@mui/material";
import {getFieldByPropName} from "../utils/utils";
import {ErrorFeedBack} from "../molecules/ErrorFeedback";
import Box from "@mui/material/Box";


interface PasswordResetCardProps {
    onComplete: () => void
}

type ChangePassword = {
    passwordCurrent: string
    password: string
    passwordConfirm: string
}

const PasswordResetCard: React.FC<PasswordResetCardProps> = ({onComplete}) => {

    const [error, setError] = useState<ErrorInfo>()
    const [complete, setComplete] = useState(false)

    const getInputField = ():InputField<string>[] => ([
        { type: 'password', propName: 'password_current', label: '現在のパスワード', value: '', required: true},
        { type: 'password', propName: 'password', label: 'パスワード: 英数字、大文字/小文字、記号必須。最短８文字', value: '', required: true},
        { type: 'password', propName: 'password_confirm', label: 'パスワード 確認', value: '', required: true},
    ])

    const setInputFieldToLogin = (changePassword: ChangePassword, fields:InputField<string>[]) => {
        changePassword.passwordCurrent = getFieldByPropName(fields, 'password_current')?.value || '';
        changePassword.password = getFieldByPropName(fields, 'password')?.value || '';
        changePassword.passwordConfirm = getFieldByPropName(fields, 'password_confirm')?.value || '';
    }

    const handleComplete = useCallback(()=> {
        setComplete(true)
        setTimeout(() => {
            onComplete()
        }, 3000)
    }, [onComplete, setComplete])

    const onAction = debounce(useCallback(async (fields: InputField<string>[]) => {

        const cp : ChangePassword = {
            passwordCurrent: '',
            password : '',
            passwordConfirm : ''
        }
        setInputFieldToLogin(cp, fields);
        if (cp.password !== cp.passwordConfirm) {
            setError({
                message: 'パスワードが一致していません。',
                timestamp: new Date().getTime(),
            })
        }

        await API.post(`/user/change_password`, cp).then((data) => {
            console.log(data)
            if (data.error) {
                setError({
                    message: '入力内容をお確かめください。',
                    timestamp: new Date().getTime(),
                })
            } else {
                handleComplete()
            }
        })
    }, [setError]))

    return (
        <Box sx={{
            width: '600px',
            height: '400px'
        }}>
            {!complete ? (<>
        <InputCard
            id={'owner-detail'}
            title={'パスワード変更フォーム'}
            fields={getInputField()}
            onAction={onAction}
            actionLabel={'変更する'}
            heightFixed={false}
        />
            <ErrorFeedBack feedbackText={error?.message||''} savedTimestamp={error?.timestamp||0} />
            </>) : (<Box sx = {{
                padding: '40px',
                position: 'relative',
                top: '120px',
                fontSize: 'x-large'}}>
                パスワードを変更しました。
            </Box>)}
        </Box>
    );
}

export default PasswordResetCard;